import navScroll from './components/navScroll'
import hamburgerMenu from './components/hamburgerMenu';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    hamburgerMenu();

    window.onscroll = () => {
      navScroll();
    }
  },
  false
)
